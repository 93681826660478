<template>
    <div class="d-flex flex-column align-center">
        <product-banner>Add More Campaigns</product-banner>
        <product-card id="501">
            <template #title>1 DeFi Distribution</template>
            <template #description>
                Share your crypto news with the world. Crypto Premium Credits
                place your announcements on 75+ crypto blogs and news sites that
                are included in the Google News database. Share your
                cryptocurrency and blockchain news including new technology,
                innovations, financial changes, partnerships or news coins, NFts
                and tokens.
            </template>
        </product-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ProductBanner, ProductCard } from '@/components/ProductCard';
@Component({
    components: {
        ProductBanner,
        ProductCard
    }
})
export default class Crypto extends Vue {
    mounted() {
        this.$emit(
            'appointment',
            'https://try.ampifire.com/meetings/jamieh2/defi'
        );
    }
}
</script>
